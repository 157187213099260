import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import CircularText from '../components/CircularText';
import axios from 'axios';
import '../css/editManifestazione.css';

function EditManifestazione() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nome: '',
    club: '',
    data_inizio: '',
    data_fine: '',
    locandina: null,
    pdfs: []
  });
  const [existingPdfs, setExistingPdfs] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchManifestazione = async () => {
      try {
        const response = await axios.get(`https://${process.env.REACT_APP_API_BASE_URL}/manifestazione/${id}`);
        const data = response.data;
        
        setFormData({
          nome: data.nome || '',
          club: data.club || '',
          data_inizio: data.data_inizio ? data.data_inizio.split('T')[0] : '',
          data_fine: data.data_fine ? data.data_fine.split('T')[0] : '',
          locandina: null,
          pdfs: []
        });

        // Handle existing PDFs
        if (data.pdfs) {
          try {
            // If pdfs is a string, parse it, otherwise use it directly
            const parsedPdfs = typeof data.pdfs === 'string' ? JSON.parse(data.pdfs) : data.pdfs;
            setExistingPdfs(Array.isArray(parsedPdfs) ? parsedPdfs : []);
          } catch (e) {
            console.error('Error parsing PDFs:', e);
            setExistingPdfs([]);
          }
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching manifestazione:', err);
        setError('Errore nel caricamento dei dati');
        setLoading(false);
      }
    };

    fetchManifestazione();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const formDataToSend = new FormData();
    formDataToSend.append('nome', formData.nome);
    formDataToSend.append('club', formData.club);
    formDataToSend.append('data_inizio', formData.data_inizio);
    formDataToSend.append('data_fine', formData.data_fine);

    if (formData.locandina) {
      formDataToSend.append('locandina', formData.locandina);
    }

    // Append new PDFs
    if (formData.pdfs && formData.pdfs.length > 0) {
      for (let pdf of formData.pdfs) {
        formDataToSend.append('pdfs', pdf);
      }
    }

    try {
      await axios.put(`https://${process.env.REACT_APP_API_BASE_URL}/manifestazione/${id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      navigate('/manifestazioni');
    } catch (err) {
      console.error('Error updating manifestazione:', err);
      setError('Errore durante l\'aggiornamento');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'pdfs') {
      setFormData(prev => ({
        ...prev,
        pdfs: [...prev.pdfs, ...files]
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: files[0]
      }));
    }
  };

  if (loading) {
    return       <CircularText
    text="CRONO*ITALIA*"
    onHover="speedUp"
    spinDuration={20}
    className="custom-class"
  />;
  }

  return (
    <div className="edit-container">
      <h2>Modifica Manifestazione</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nome:</label>
          <input
            type="text"
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Club:</label>
          <input
            type="text"
            name="club"
            value={formData.club}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Data Inizio:</label>
          <input
            type="date"
            name="data_inizio"
            value={formData.data_inizio}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Data Fine:</label>
          <input
            type="date"
            name="data_fine"
            value={formData.data_fine}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Locandina:</label>
          <input
            type="file"
            name="locandina"
            onChange={handleFileChange}
            accept="image/*"
          />
        </div>

        <div className="form-group">
          <label>PDFs:</label>
          <input
            type="file"
            name="pdfs"
            onChange={handleFileChange}
            accept=".pdf"
            multiple
          />
        </div>

        {/* Display existing PDFs */}
        {existingPdfs.length > 0 && (
          <div className="existing-pdfs">
            <h3>PDF esistenti:</h3>
            <ul>
              {existingPdfs.map((pdf, index) => (
                <li key={index}>
                  <a href={`https://${process.env.REACT_APP_API_BASE_URL}/${pdf.path}`} target="_blank" rel="noopener noreferrer">
                    {pdf.name || `PDF ${index + 1}`}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        {error && <div className="error">{error}</div>}

        <div className="button-group">
          <button type="submit">Salva Modifiche</button>
          <button type="button" onClick={() => navigate('/manifestazioni')}>Annulla</button>
        </div>
      </form>
    </div>
  );
}

export default EditManifestazione;