import React, { useRef, useEffect, useState } from 'react';
import home from '../images/home2.jpg';
import '../css/home.css';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion'; 
import axios from 'axios';
import CountUp from './CountUp';

import logo1 from "../images/home/1.jpg";
import logo2 from "../images/home/2.jpg";
import logo3 from "../images/home/3.jpg";
import logo4 from "../images/home/4.jpg";
import logo5 from "../images/home/5.jpg";
import logo6 from "../images/home/6.jpg";
import logo7 from "../images/home/7.jpg";
import logo8 from "../images/home/8.jpg";
import logo9 from "../images/home/9.jpg";
import logo10 from "../images/home/10.jpg";

function Home() {
  const galleryRef = useRef(null);
  const [manifestazioni, setManifestazioni] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [animationExecuted, setAnimationExecuted] = useState(false);  // Stato per tracciare l'animazione
  const navigate = useNavigate();

  const fetchManifestazioni = async () => {
    try {
      const response = await axios.get(`https://${process.env.REACT_APP_API_BASE_URL}/manifestazioni`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-Requested-With': 'XMLHttpRequest',
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        withCredentials: true,
      });
      if (Array.isArray(response.data)) {
        setManifestazioni(response.data);
      } else {
        console.error('Data received is not an array:', response.data);
        setManifestazioni([]);
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching manifestazioni:', err);
      setError('Impossibile recuperare le manifestazioni');
      setManifestazioni([]);
      setLoading(false);
    }
  };

  const getImageUrl = (path) => {
    return `https://${process.env.REACT_APP_API_BASE_URL}/${path}`;
  };

  useEffect(() => {
    const gallery = galleryRef.current;

    // Funzione per gestire il movimento del mouse
    const handleMouseMove = (e) => {
      if (!animationExecuted) {
        const width = gallery.offsetWidth;
        const height = gallery.offsetHeight;
        const x = e.pageX - gallery.offsetLeft;
        const y = e.pageY - gallery.offsetTop;

        const rotateX = ((y / height) - 0.5) * 5;
        const rotateY = ((x / width) - 0.5) * -5;

        gallery.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
      }
    };

    // Funzione per resettare la trasformazione
    const resetTransform = () => {
      if (!animationExecuted) {
        gallery.style.transform = 'rotateX(0deg) rotateY(0deg)';
      }
    };

    if (gallery) {
      gallery.addEventListener('mousemove', handleMouseMove);
      gallery.addEventListener('mouseleave', resetTransform);
    }

    fetchManifestazioni();

    return () => {
      if (gallery) {
        gallery.removeEventListener('mousemove', handleMouseMove);
        gallery.removeEventListener('mouseleave', resetTransform);
      }
    };
  }, [animationExecuted]);  // Aggiungi `animationExecuted` come dipendenza

  // Aggiorna lo stato quando l'animazione è eseguita
  useEffect(() => {
    if (!animationExecuted) {
      setAnimationExecuted(true);
    }
  }, [animationExecuted]);

  const images = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10];

  return (
    <>
      <div className='container'>
        <motion.div
          className='welcomeWrite'
          initial={{ opacity: 0, y: -50 }}  
          whileInView={{ opacity: 1, y: 0 }}   
          transition={{ duration: 1 }}   
        >
          <h1>Benvenuto in Crono Italia</h1>
          <h2><i>Dove il tempo e le macchine creano gli amici</i></h2>
        </motion.div>
        <div className='bgImg'>
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }} 
          >
            <img src={home} alt="homepage" className='imgHome' />
          </motion.span>
        </div>
      </div>

      <div className='start'>
        <motion.div
          className='block'
          initial={{ scale: 0.8 }}  
          whileInView={{ scale: 1 }}  
          transition={{ duration: 0.5 }}
        >
          <h1 className='titleBlock'>SCOPRI DI PIU' SU COSA FACCIAMO</h1>
        </motion.div>

        <div className='allBlock'>
          <motion.div
            className='block1'
            initial={{ opacity: 0, x: -100 }} 
            whileInView={{ opacity: 1, x: 0 }} 
            transition={{ duration: 0.8 }}
          >
            <span className='flexTitle'>
              <span className='numberBlock'>1.</span>
              <h1 className='blockTitle'> Rivelamento Tempi ed Elaborazione dei Dati Acquisiti</h1>
            </span>
            <h4 className='descriptionBlock'>Con i nostri sistemi all’avanguardia, vi forniamo prospetti dettagliati, tempi di gara e classifiche in modo rapido e preciso, per un’esperienza senza attese e sempre sotto controllo!</h4>
          </motion.div>
          <hr className='hrBlock' />
          <motion.div
            className='block2'
            initial={{ opacity: 0, x: 100 }} 
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <span className='divBlock2'>
              <span className='flexTitle'>
                <span className='numberBlock'>2.</span>
                <h1 className='blockTitle'> Organizzazione Tecnica Completa della Manifestazione</h1>
              </span>
              <h4 className='descriptionBlock'>Siamo al vostro fianco per organizzare la manifestazione in ogni dettaglio, dalla realizzazione del roadbook fino alla completa gestione dell'allestimento delle prove, garantendo un evento impeccabile e ben strutturato.</h4>
              <Link to='/contattaci' className='buttonBlock'>Clicca qui</Link>
            </span>
          </motion.div>
          <hr className='hrBlock' />
          <motion.div
            className='block3'
            initial={{ opacity: 0, y: 50 }} 
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
           <span className='divBlock3'>
             <span className='flexTitle'>
               <span className='numberBlock'>3.</span>
               <h1 className='blockTitle'> Classifiche e Archivio Web</h1>
             </span>
            <h4 className='descriptionBlock'>Cliccando qua sotto è possibile visualizzare l'elenco completo di tutte le manifestazioni organizzate fino a oggi, con dettagli su eventi passati e traguardi raggiunti. Scopri le iniziative realizzate, le partecipazioni più significative e l'impatto che queste manifestazioni hanno avuto nel tempo.</h4>
            <Link to='/manifestazioni' className='buttonBlock'>Clicca qui</Link>
           </span>
          </motion.div>
        </div>
      </div>
      <hr className='hrEndBlock' />

      <div className='containerGalleryText'>
        <motion.div className="gallery" ref={galleryRef}>
          {images.map((image, index) => (
            <motion.div
              key={index}
              className={`item item${index + 1}`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }} 
              transition={{
                duration: 0.5,          
                delay: index * 0.3 
              }}
              whileHover={{ scale: 1.1 }}
            >
              <img src={image} alt={`image ${index + 1}`} className="gallery-image" />
            </motion.div>
          ))}
        </motion.div>

        <div className='textDiv'>
          <motion.h1
            className='titleDiv'
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Oltre 20 anni di esperienza nel settore del motorismo storico e piú di <CountUp
              from={0}
              to={500}
              separator=","
              direction="up"
              duration={1}
              className="count-up-text"
            /> manifestazioni cronometrate
          </motion.h1>

          <motion.hr
            className='hrBlock'
            initial={{ opacity: 0, width: 0 }}
            whileInView={{ opacity: 1, width: '100%' }}
            transition={{ duration: 1 }}
          />

          <motion.h3
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Siamo presenti sul territorio italiano da oltre 20 anni, un'esperienza che ci ha reso professionisti nel settore. <br></br>
            Abbiamo contribuito a piú di 500 manifestazioni, garantendo servizi di cronometraggio tempestivi e di alta qualitá.
          </motion.h3>

          <motion.div
            className='flexDiv'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            <motion.div
              className='colDiv'
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.6, duration: 0.8 }}
            >
              <h3>Se volessi scoprire di più su chi siamo puoi cliccare qua sotto</h3>
              <motion.div
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.3 }}
              >
                <Link to='chi-siamo' className='buttonDiv'>
                  Clicca qui per scoprire chi siamo
                </Link>
              </motion.div>
            </motion.div>

            <motion.div
              className='colDiv'
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.6, duration: 0.8 }}
            >
              <h3>Se invece hai delle domande da farci puoi cliccare qui</h3>
              <motion.div
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.3 }}
              >
                <Link to='contattaci' className='buttonDiv' onClick={() => { window.scrollTo(0, 0); }} >
                  Clicca qui per contattarci
                </Link>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>
      <div className='manifestazioni'>
        <hr className='hr' />
        <div className='blockman'>
          <motion.h1
            className='textHome'
            initial={{ opacity: 0, y: -30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Manifestazioni Passate
          </motion.h1>
        </div>

        <div className='disposizioneManifestazioni'>
          <div className="manifestazioni-grid">
            {Array.isArray(manifestazioni) && manifestazioni.length > 0 ? (
              manifestazioni
                .slice(0, 9)
                .map((manifestazione) => (
                  <motion.div 
                    key={manifestazione.id} 
                    className="manifestazione-card-hover"
                    onClick={() => navigate(`/manifestazione/${manifestazione.id}`)}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={getImageUrl(manifestazione.locandina)}
                      alt={manifestazione.nome}
                      className="manifestazione-image"
                    />
                    <div className="manifestazione-info-hover">
                      <h3>{manifestazione.nome || 'Titolo non disponibile'}</h3>
                      <p>{manifestazione.club || 'Club non disponibile'}</p>
                      <p>Fino al {manifestazione.data_fine ? 
                        new Date(manifestazione.data_fine).toLocaleDateString('it-IT', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric'
                        }) : 'Data non disponibile'}</p>
                    </div>
                  </motion.div>
                ))
            ) : (
              <div className="no-manifestazioni">Nessuna manifestazione disponibile</div>
            )}
          </div>
        </div>
        {manifestazioni.length > 9 && (
            <motion.div
              className="view-all-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link to="/manifestazioni" className="buttonBlock" onClick={() => window.scrollTo(0, 0)}>
                Vedi tutte le manifestazioni
              </Link>
            </motion.div>
          )}
      </div>

      <div className='endHome'>
        <div className='textEnd'>
          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Vuoi chiederci qualcosa?
          </motion.h3>
          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            Siamo qui per rispondere a tutte le tue domande!
          </motion.h3>
        </div>
        <motion.div
          whileHover={{ scale: 1.1 }}
        >
          <Link to="/contattaci" className='contactButton' onClick={() => { window.scrollTo(0, 0); }} >
            Clicca qui
          </Link>
        </motion.div>
      </div>
    </>
  );
}

export default Home;
