import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import CircularText from '../components/CircularText';
import '../css/manifestazioneDettaglio.css';

function ManifestationDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [manifestazione, setManifestazione] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchManifestazione = async () => {
      try {
        const response = await axios.get(`https://${process.env.REACT_APP_API_BASE_URL}/manifestazione/${id}`);
        setManifestazione(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching manifestazione:', err);
        setError('Errore nel caricamento dei dati');
        setLoading(false);
      }
    };

    fetchManifestazione();
  }, [id]);

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };


  const getImageUrl = (path) => {
    return `https://${process.env.REACT_APP_API_BASE_URL}/${path}`;
  };


  const renderPdfs = () => {
    if (!manifestazione.pdfs) return null;

    let pdfs = [];
    try {
      // Parse the PDFs JSON string if it's a string
      pdfs = typeof manifestazione.pdfs === 'string' 
        ? JSON.parse(manifestazione.pdfs) 
        : manifestazione.pdfs;
    } catch (e) {
      console.error('Error parsing PDFs:', e);
      return null;
    }

    if (!Array.isArray(pdfs) || pdfs.length === 0) return null;

    return (
      <div className="pdf-list">
            {pdfs.map((pdf, index) => (
              <motion.a
              key={index}
              href={`https://${process.env.REACT_APP_API_BASE_URL}/${pdf.path}`}
              target="_blank"
              rel="noopener noreferrer"
              className="download-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {pdf.name || `Documento ${index + 1}`}
            </motion.a>
            ))}
          </div>
        );
      };

  if (loading) {
    return (
      <CircularText
  text="CRONO*ITALIA*"
  onHover="speedUp"
  spinDuration={20}
  className="custom-class"
/>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="error-message"
        >
          {error}
        </motion.div>
      </div>
    );
  }

  if (!manifestazione) {
    return (
      <div className="not-found-container">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="not-found-message"
        >
          Manifestazione non trovata
        </motion.div>
      </div>
    );
  }

  return (
    <div className="detail-container">
      <motion.div
        className="detail-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="detail-header"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <h1>{manifestazione.nome}</h1>
          <div className="detail-actions">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate('/manifestazioni')}
              className="back-button"
            >
              Torna alla lista
            </motion.button>
          </div>
        </motion.div>

        <div className="detail-grid">
          <motion.div
            className="detail-image"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
          >
            <img
            src={getImageUrl(manifestazione.locandina)}
            alt={manifestazione.nome}
            className="manifestazione-img-detail"
          />
          </motion.div>

          <motion.div
            className="detail-info"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4 }}
          >
            <div className="info-group">
              <h3>Club</h3>
              <p>{manifestazione.club}</p>
            </div>

            <div className="info-group">
              <h3>Date</h3>
              <p>
                Dal {new Date(manifestazione.data_inizio).toLocaleDateString()} al{' '}
                {new Date(manifestazione.data_fine).toLocaleDateString()}
              </p>
            </div>

            <div className="info-group">
              <h3>Documenti</h3>
              <motion.a
                href={`https://${process.env.REACT_APP_API_BASE_URL}/${manifestazione.pdfs}`}
                target="_blank"
                rel="noopener noreferrer"
                whileHover={isTouchDevice() ? undefined : { scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >              
              {renderPdfs()}
              </motion.a>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}

export default ManifestationDetail;